:root{
  --blr-sky:#00DBFF;
  --blr-dull-sky:#68A8DD;
  --blr-purple:#BF71DF;
  --blr-dull-pink:#C75CA2;
  --blr-pink:#FF00DC;
}

/**========Global CSS========*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sidebar-active{
  color: var(--bs-menu-item-hover-color) !important;
  background-color: var(--bs-menu-item-active-bg) !important ;
}

/**========background colors========*/
.blr-bg-sky{
  background-color: var(--blr-sky) !important;
}
.blr-bg-dull-sky{
  background-color: var(--blr-dull-sky) !important;
}
.blr-bg-dull-pink{
  background-color: var(--blr-dull-pink) !important;
}
.blr-bg-pink{
  background-color: var(--blr-pink) !important;
}
.blr-bg-purple{
  background-color: var(--blr-purple) !important;
}

/**========font-colors========*/
.blr-text-sky{
  color: var(--blr-sky);
}
.blr-text-dull-sky{
  color: var(--blr-dull-sky);
}
.blr-text-pink{
  color: var(--blr-pink);
}
.blr-text-purple{
  color: var(--blr-purple);
}
.blr-text-dull-pink{
  color: var(--blr-dull-pink);
}

/**========badge colors========*/
.badge-success-lighten{
  color: #42d29d;
  background-color: rgba(66, 210, 157, .18);
}
.badge-warning-lighten{
  color: #f9bc0d;
  background-color: rgba(249, 188, 13, .18);
}
.badge-danger-lighten{
  color: #fa6767;
  background-color: rgba(250, 103, 103, .18);
}
.badge-info-lighten {
  color: #44badc;
  background-color: rgba(68, 186, 220, .18);
}
.badge-primary-lighten {
  color: #3688fc;
  background-color: rgba(54, 136, 252, .18);
}
.badge-secondary-lighten {
  color: #919ca7;
  background-color: rgba(145, 156, 167, .18);
}
.badge-purple-lighten {
  color: #811fd1;
  background-color: rgba(129, 31, 209, 0.18);
}
.badge {
  padding: 6px 10px !important; 
}

/**========Dashboard css end========*/
.logo-sizing{
  justify-content: start !important;
  margin-left: 16px !important;
}
.blr-label-padding{
  padding-left:70px  !important;
}
.card{
  margin-bottom: 15px !important;
  border:var(--bs-card-border-width) solid rgb(165 162 162 / 24%) !important;
  border-radius: 15px !important;
}
.blr-team-name{
  border-top:var(--bs-card-border-width) solid rgb(165 162 162 / 24%) !important;
}

/**========OTP========*/
.otp-container {
  margin: 0 50px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 767px) and (max-width: 992px) {
  .otp-container {
    margin: 0px;
  }
}
@media (min-width: 0px) and (max-width: 484px) {
  .otp-container {
    margin: 0 -7px;
  }
}
.otp__digit {
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.2rem;
  color: #000;
}
._ok {
  color: green;
}
._notok {
  color: red;
}
.btn-primary-dark-blue-custom{
  --bs-btn-color: #fff;
    --bs-btn-bg: #004c74;
    --bs-btn-border-color: #346ee0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #034467;
    --bs-btn-hover-border-color: #2a58b3;
    --bs-btn-focus-shadow-rgb: 82, 132, 229;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2a58b3;
    --bs-btn-active-border-color: #2753a8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #346ee0;
    --bs-btn-disabled-border-color: #346ee0;
    border: 0;
    padding: 8px 15px;
}
.btn-primary-yellow-custom{
    --bs-btn-color: #fff;
    --bs-btn-bg: #37871a;
    --bs-btn-border-color: #346ee0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2a7b0d;
    --bs-btn-hover-border-color: #2a58b3;
    --bs-btn-focus-shadow-rgb: 82, 132, 229;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2a58b3;
    --bs-btn-active-border-color: #2753a8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #346ee0;
    --bs-btn-disabled-border-color: #346ee0;
    border: 0;
}
.pagination-rounded{
  float: right;
}
.resend-otp-email{
  margin-bottom: 25px;
  float: right;
  cursor: pointer;
}
.img-width{
  width: 170px;
}
.button-wrapper {
  position: relative;
  text-align: center;
  margin:auto;
}
.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  /* width: 100%; */
  cursor: pointer;
  color: #fff;
  padding: 5px 7px;
  text-transform:uppercase;
  font-size:12px;
}
.blr-bg-gradient{
  background: var(--blr-bg-gradient);
}
#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 33px;
  top: 0;
  opacity: 0;
  left: 0;
  cursor: pointer;
}
.blr-line-height-table{
  line-height: 40px;
}

.blr-line-height-table td i{
  font-size: 20px;
  line-height: 2.2;
}

/**========Dropdowns========*/
.dropdown-item.active, .dropdown-item:active{
  background-color: var(--blr-dull-sky) !important;
}
.dropdown-menu{
  padding: 2px !important;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: var(--blr-dull-sky);
  border-radius: 5px;
  color: #fff;
}

/**========Buttons========*/
.btn {
  --bs-btn-padding-x: 15px !important;
  --bs-btn-padding-y:4px !important;
  --bs-btn-font-size: 17px !important;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  /* --bs-btn-border-width: var(--bs-border-width); */ 
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0px 2px 6px 0px;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.15rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--blr-dull-sky);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.btn:hover{
  color:var(--blr-dull-sky) !important;
  background-color: #fff !important;
  border-color: var(--blr-dull-sky) !important;
}
.btn i{
  position: relative;
  top: 3px;
}

/**========Pagination========*/
.previous .bx, .next .bx{
  position: relative;
  top: 2px;
}
.pagination .previous a{
  text-decoration: none;
  color: gray;
}
.pagination .next a{
  text-decoration: none;
  color: gray;
}
.pagination .previous a:hover{
  color: white;
}
.pagination .previous a:hover{
  color: white;
}
.pagination .next a:hover{
  color: white;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  float: right;
  text-decoration: none;
  border-radius: 10px;
}
.pagination li {
  margin: 0 5px;
  cursor: pointer;
  color:gray;
  padding: 4px 10px;
  transition: all 0.2s ease-in;
  border-radius: 5px;
}
.pagination li:hover {
  color:white;
  background: var(--blr-dull-sky);
  font-weight: bold;
}
.pagination li:hover.previous .bx, .pagination li:hover.next .bx{
  color: #fff;
}
.pagination-active-page {
  color: white !important;
  background-color: var(--blr-dull-sky) !important;
  font-weight: bold;
}
@media (max-width: 576px) {
  .pagination-container {
    flex-direction: column;
    align-items: center;
  }
  .pagination li {
    margin: 1.5px ;
    font-size: 12px;
  }
}
@media (max-width: 392px) {
  .pagination-container {
    flex-direction: column;
    align-items: center;
  }
  .pagination li {
    padding: 3px 6px;
    margin: 2px ;
    font-size: 14px;
  }
}
/**========Images========*/
.user-img{  
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img-view{
  margin: auto;
}
.blr-user-image{
  border-radius:50%;
  cursor: pointer;
  object-fit: contain;
  min-height: 170px;
  height: 170px;
  max-width: 170px;
  width: 170px;
}

/**========Tables========*/
.table th{
  font-weight: 500 !important;
}
.table{
  vertical-align: middle;
  text-align: center !important;
}
#scroll-horizontal-datatable tbody td i{
  font-size: 17px !important;
}

/**========Forms========*/
.form-control:focus,.form-select:focus{
  color: unset !important;
  background-color:transparent !important;
  border-color: unset !important;
  outline: unset !important;
  box-shadow: none !important;
}

/**========Modal========*/
@keyframes slideDown {
  from {
    transform: translate(-50%, -200%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
@keyframes slideUp {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -200%);
  }
}
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  z-index: 9999999999999999; 
  animation: fadeIn 0.3s ease;
}
.confirmation-dialog {
  width: 500px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000;
  animation: slideDown 0.5s ease forwards;
}
.confirmation-dialog p{
  color: #000 !important;
}
.confirmation-dialog.slide-up {
  animation: slideUp 0.5s ease forwards;
}
.confirmation-dialog p {
  margin-bottom: 10px;
}
.confirmation-dialog button {
  margin-right: 10px;
}
@keyframes slideDown {
  from {
    transform: translate(-50%, -200%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
@keyframes slideUp {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -200%);
  }
}
.user-button-wrapper {
  position: relative;
  text-align: center;
  margin:auto;
}
.user-button-wrapper span.label {
  position: relative;
  top: -100px;
  z-index: 0;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  color: #fff;
  padding: 5px 7px;
  text-transform:uppercase;
  font-size:12px; 
}

/**========Base styles for all devices========*/
.user-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  z-index: 9999999999999999; 
  animation: fadeIn 0.3s ease;
}
.user-confirmation-dialog {
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000; 
  animation: slideDown 0.5s ease forwards;
}
.player-confirmation-dialog {
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #212121;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000;
  animation: slideDown 0.5s ease forwards;
}
.user-confirmation-dialog.slide-up {
  animation: slideUp 0.5s ease forwards;
}
.user-confirmation-dialog button {
  padding: 5px 10px;
}
.user-confirmation-dialog button:hover {
  background-color: #0056b3;
}
.teams-container {
  display: flex;
  flex-wrap: wrap;
}
.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14%;
  margin: 0% 1%;
  box-sizing: border-box;
}
.team-image {
  width: 40%;
  height: auto;
  margin-bottom: 10px;
}
.team-name {
  text-align: center;
  margin: 0;
  font-size: 1em;
}
.blr-tshirt-style{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin:0;
  z-index: 1;
}

.blr-fav-player{
  height: 130px;
  padding: 15px 10px 10px 10px;
  text-align: center;
  border: 1px solid rgba(128, 128, 128, 0.141);
  border-radius: 10px;
}
/**========Extra Large Devices (Desktops, 1200px and up)========*/
@media (max-width: 1200px) {
  #upload-modal{
    width: 25% !important;
  }
  .user-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .player-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .team-card {
    width: 23%;
  }
}
@media (max-width: 992px) {
  .user-confirmation-dialog {
    width: 80%;
    height: auto;
  }
  .player-confirmation-dialog {
    width: 80%;
    height: auto;
  }
  .team-card {
    width: 30%;
  }
}

/**========view/show detail pages css========*/
.blr-player-view{
  position: relative;
  top: 2px;
}

/**========match manager ========*/
.blr-img-league img{
  width: 100% !important;
  height: 250px !important;
  object-fit: contain;
}

.match-dark-confirmation-dialog {
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #212121;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000;
  animation: slideDown 0.5s ease forwards;
}
.match-light-confirmation-dialog {
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000;
  animation: slideDown 0.5s ease forwards;
}

/**========Adjust for smaller devices========*/
@media (max-width: 768px) {
  .match-dark-confirmation-dialog{
    width: 70%;
    height: auto;
  }.match-light-confirmation-dialog{
    width: 70%;
    height: auto;
  }
  .user-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .player-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .team-card {
    width: 45%;
  }
  .blr-fav-player{
    height: auto !important;
  }
  .search-width{
    width: 30% !important;
  }
}
@media (max-width: 576px) {
  .match-dark-confirmation-dialog{
    width: 70%;
    height: auto;
  }.match-light-confirmation-dialog{
    width: 70%;
    height: auto;
  }
  .user-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .player-confirmation-dialog {
    width: 70%;
    height: auto;
  }
  .confirmation-dialog{
    width: 320px;
  }
  .team-card {
    width: 100%;
  }
  .search-width{
    width: 100% !important;
  }
}
@media (max-width: 375px) {
  .match-dark-confirmation-dialog{
    overflow-y: auto;
    width: 90%;
    height: auto;
  }.match-light-confirmation-dialog{
    overflow-y: auto;
    width: 90%;
    height: auto;
  }
  .team-card {
    width: 100%;
  }
  .user-confirmation-dialog {
    overflow-y: auto;
    width: 90%;
    height: auto;
  }
  .player-confirmation-dialog {
    overflow-y: auto;
    width: 90%;
    height: auto;
  }
  .confirmation-dialog{
    width: 320px;
  }
}
.fav-player-view {
  max-height: 60vh;
  overflow: auto;
  overflow-x: hidden;
}
.team-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999999999999999;
  animation: fadeIn 0.3s ease;
}
.team-confirmation-dialog {
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999999999999000; 
  animation: slideDown 0.5s ease forwards;
}
.text-dark {
  color: black;
}
.text-white {
  color: white;
}
.scroll{
  max-height: 60vh;
  overflow: auto;
}
@media (max-width: 576px) {
  .card {
    margin-bottom: 10px;  
  }
}
.authenticate-bg{
  background : linear-gradient(to right, rgba(0, 219, 255, 1), rgba(104, 168, 221, 1), rgba(191, 113, 223, 1),rgba(199, 92, 162, 1),rgba(255, 0, 220, 1)) !important;
}                                                                   
.checkbox.style-h {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-h input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-h input:checked ~ .checkbox__checkmark {
  background-color: var(--blr-purple);
}
.checkbox.style-h input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  top: 1px;
}
.checkbox.style-h input:checked ~ .checkbox__body {
  background-position: 0 0;
  color: #000;
}
.checkbox.style-h:hover input ~ .checkbox__checkmark {
  background-color: var(--blr-dull-sky);
}
.checkbox.style-h:hover input:checked ~ .checkbox__checkmark {
  background-color: var(--blr-dull-sky);
}
.checkbox.style-h:hover .checkbox__body {
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
}
.checkbox.style-h .checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  height: 22px;
  width: 22px;
  background-color: var(--blr-dull-sky);
  transition: background-color 0.25s ease;
  border-radius: 4px;
  box-shadow: inset 2px 2px 4px rgba(0,0,0,0.1), inset -2px -2px 4px rgba(255,255,255,0.4);
}
.checkbox.style-h .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  width: 8px;
  height: 16px;
  border: solid #fff;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 0.25s ease;
}
.checkbox.style-h .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  transition: font-weight 0.25s ease;
  font-weight: bold;
  color: #333;
  background: linear-gradient(to right, rgb(176, 244, 255), rgb(195, 228, 255), rgb(234, 192, 251)) ;
  background-size: 200% 100%;
  background-position: 100% 0;
  padding: 15px;
  padding-left: 52px;
  padding-right: 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  transition: box-shadow 0.25s ease, background 1s ease, color 0.25s ease;
}

.form-select option,select option{
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
}

.view-img-thumbnail {
  padding: 0.25rem;
  /* background-color: var(--bs-secondary-bg); */
  /* border: var(--bs-border-width) solid var(--bs-border-color); */
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}
/* react-icon */
.button-toggle-menu svg{
  position: relative;
  bottom: 5px;
}
.menu-icon svg{
  margin-top: -2px !important;
  height: 20px !important;
  width: 20px !important;
}
.nav-link svg{
  height: 22px !important;
  width: 22px !important;
}
/* react-icon end */


.table-underline th{
  border-style: none;
}.table-underline td{
  border-style: none;
}

.nav-tabs .active{
  border: none !important;
  background: var(--blr-bg-gradient) !important;
}
.nav-tabs {
  border: none !important;
  background: #ababab;
}.nav-tabs :hover{
  border: none !important;
}
.nav-tabs .nav-link .active{
  border: none !important;
  border-radius: 0px !important;
}
.nav-tabs .nav-link{
  color: white;
  border: none !important;
  margin-bottom:unset !important;
  border-radius: 0px !important;
}
.blr-team-bg{
  background: transparent !important;
  border : 1px solid gray;
}
.radius-20{
  border-radius:20px !important;
}
.img-label{
  position: relative;
  left: 26px;
}
#upload-match-img {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 2%;
  height: 25px;
  top: -134px;
  left: 27px;
  opacity: 0;
  cursor: pointer;
}
#upload-match-img-2 {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 2%;
  height: 25px;
  top: -134px;
  left: 27px;
  opacity: 0;
  cursor: pointer;
}
#upload-modal {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 20%;
  height: 33px;
  top: -32px;
  opacity: 0;
  cursor: pointer;
}
.import-match-icon{
  border: 1px solid gray;
  font-size: 35px !important;
  border-radius: var(--bs-border-radius);
  padding: 5px;
  cursor: pointer;
}
.import-match-icon-checkboard{
  border: 1px solid gray;
  font-size: 35px !important;
  border-radius: var(--bs-border-radius);
  padding: 6px;
  cursor: pointer;
}

.react-datepicker__current-month{
  font-size: 1rem !important;
}
.blr-date-picker{
  padding: 5px 10px;
  background: gray;
}
.blr-image{
  height: auto !important;
}
.blr-image img{
  position: relative;
  height: 303px !important;
}
.date-picker{
  border :none !important;
}
.blr-icon:focus {
  outline: unset !important;
}

.jodit-toolbar-button_fullsize{
  display: none !important;
}
 .jodit-ui-group__speechRecognize{
  display: none !important;
}
 .jodit-workplace{
  height: 410px !important;
}
 .jodit-container:not(.jodit_inline) .jodit-workplace,.jodit-container:not(.jodit_inline){
  background: transparent !important;
}
 .jodit-status-bar{
  display: none !important;
}
 .jodit-container:not(.jodit_inline) { 
  border: 1px solid var(--bs-border-color) !important;  
}
.jodit-add-new-line span{
  display: none !important;
}
.news-image{
  width: 75% !important;
}
.jodit-react-container{
  width: 100%;
  max-width: 1000px;
}
.blr-news-view,.img-view{
  max-height: 350px !important;
  /* width: 320px !important; */
  max-width: 500px !important;
  object-fit: cover;
  cursor: pointer;
}


/**========== Checkbox css ==========*/
.blr-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.blr-check label {
  position: relative;
  cursor: pointer;
}
.blr-check label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--blr-dull-sky);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.blr-check input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 8px;
  width: 5px;
  height: 12px;
  border: solid var(--blr-dull-sky);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.news-image{
  width: 200px !important;
  object-fit: contain;
}

/* save button */
#ripple {
  background: linear-gradient(90deg, #0162c8, #55e7fc);
  overflow: hidden;

  span {
    position: absolute;
    background: #fff;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: rippleAnimation 1s linear infinite;
  }
  @keyframes rippleAnimation {
    0% {
      width: 0px;
      height: 0px;
      opacity: 0.5;
    }
    100% {
      width: 500px;
      height: 500px;
      opacity: 0;
    }
  }
}

/* Ensure that the parent container adapts to different screen sizes */
.responsive-profile {
  padding: 20px;
  max-width: 100%;
}

.profile-image {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  min-height: 80px;
  height: 80px;
  max-width: 80px;
  width: 80px;
  border: none;
}

@media (min-width: 768px) {
  .profile-image {
    min-height: 100px;
    height: 100px;
    max-width: 100px;
    width: 100px;
  }
}

@media (min-width: 1200px) {
  .profile-image {
    min-height: 120px;
    height: 120px;
    max-width: 120px;
    width: 120px;
  }
}

.bets-light-background{
  background: rgb(33 37 41 / 8%) !important;
}
.bets-dark-background{
  background: #474755 !important;
}

/* Responsive css */
@media (max-width:991px){
  .blr-image img{
    height: auto !important;
  }
  .button-wrapper span.label {
    width: 100%;
  }  
  .h5,h5 {
    font-size: 16px !important;
}
#upload{
  width: 100%;
}
#upload-modal{
  width: 100% !important;
}
.d-flex-remove{
  display: grid !important;
}
.blr-news-view{
  height: auto !important;
  width: 100% !important;
  max-width: 100% !important;
}
}
@media (max-width:767px){
  #upload-modal{
    width: 100%;
  }
  .pagination{
    float: unset !important;
    justify-content: center;
  }
  .team-confirmation-dialog{
    width: 90% !important;
  }
  .blr-reverse{
    flex-direction: column-reverse;
  }
  .h4, h4{
    font-size: 16px !important;
  }
  .news-image{
    width: 100% !important;
  }
  .news-image{
    width: 100% !important;
  }
  .img-responsive{
    flex-direction: column;
  }
  .padding-responsive{
    padding: 0px !important;
  }
  .bets-responsive{
    width: 100% !important;
  }
  .import-bets-modal{
    display: flex;
    flex-direction: column !important;
  }
}
.question-type-column {
  white-space: nowrap; 
  word-wrap: break-word; /* Ensures long words break correctly */
  /* min-width: 100%;  */
  /* max-width: 100%;   */
}


/* jodit text-editor */
.jodit-dialog_active_true, .jodit-dialog_modal_true .jodit-dialog__overlay{
  display: none !important;
}
/* jodit text-editor end */